<template>
  <slot></slot>
  <div class="docs-links" v-if="!userStore.$state.isKeyboardOpen">
    <a :href="`${currenDomen}/docs/politika.html`" target="_blank" rel="noopener noreferrer"
      >Политика конфиденциальности</a
    >
    <sup></sup>
    <a :href="`${currenDomen}/docs/soglashenie.html`" target="_blank" rel="noopener noreferrer"
      >Пользовательское соглашение</a
    >
  </div>
</template>

<script setup lang="ts">
import { useFormStore } from '~/store/user'

const currenDomen = ref(window.location.origin)
const userStore = useFormStore()

onMounted(() => {
  if (window.Tawk_API && typeof window.Tawk_API.hideWidget === 'function') {
    window.Tawk_API.hideWidget()
  }
  userStore.setScreenSizeInCSS()
})
onUnmounted(() => {
  if (window.Tawk_API && typeof window.Tawk_API.hideWidget === 'function') {
    window.Tawk_API.showWidget()
  }
})
</script>

<style lang="scss">
@media screen and (min-width: 200px) and (max-width: 767px) {
  .docs-links {
    flex-direction: column;
    max-height: 50px;
    a {
      margin: 0;
    }
    sup {
      display: none;
    }
  }
}
@import '../assets/styles/global.scss';
.docs-links {
  position: fixed;
  bottom: 16px;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-items: center;
  gap: 12px;
  sup {
    width: 1px;
    height: 12px;
    background: #dbdae5;
  }
  a {
    color: var(--violet-400);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
  }
}
</style>
